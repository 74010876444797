import { all, fork } from 'redux-saga/effects'

import appSagas from '../App.sagas'
import loginSagas from '../header/login/Login.sagas'
import profileSagas from '../profile/Profile.sagas'

export default function* rootSagas() {
  yield all([
    fork(appSagas),
    fork(loginSagas),
    fork(profileSagas)
  ])
}
