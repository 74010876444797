import { makeStyles } from "@mui/styles";

export default makeStyles(({ customShadows, palette }) => ({
    container: {
        height: '100%',
        padding: '2rem 7rem 0rem 7rem',
    },
    leftNavbar: {
        border: '1px solid #DCDFE7',
        backgroundColor: palette.common.white,
        boxShadow: customShadows[1],
        borderRadius: 12,
        '& .ProfileDetails': {
            margin: 'auto',
            '& > *': {
                marginBottom: 3
            }
        },
        '& .ProfileTabs > li > a': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: "1rem 1.5rem",
            cursor: 'pointer'
        }
    },
    rightSection: {
        height: '100%',
    },
    downloadApp: {
        maxWidth: "100%",
        margin: 'auto'
    },
    tabCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: '2rem',
        border: '1px solid #DCDFE7',
        boxShadow: customShadows[1],
        borderRadius: 12,
        height: '100%',
        '& img': {
            marginBottom: 15,
        }
    }
}))