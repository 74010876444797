import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'

import defaultTheme from 'neerbi-fe-modules/mui-theme'
import HomeComponent from './home'
import PageNotFound from './page-not-found'
import Header from './header';
import Footer from './footer'

import ProfileComponent, { ProfileHomeComponent } from './profile'
import OrdersComponent from "./profile/orders"
import PaymentsComponent from "./profile/payments"
import AddressComponent from "./profile/address"
import SettingsComponent from "./profile/settings"
import { PrivateRoute } from 'neerbi-fe-modules/components/private-access'
import Notification from 'neerbi-fe-modules/components/notification'
import { validateAuthentication } from './App.actions'

const theme = createTheme(defaultTheme);

function AppWrapper() {
  return (
    <>
      <Header />
      <Box
        sx={{
          height: { xs: 'calc(100% - 56px - 240px)', sm: 'calc(100% - 56px - 240px)', md: 'calc(100% - 72px - 240px)' },
          overflow: 'auto'
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </>
  )
}

function App() {
  const dispatch = useDispatch()
  const [cookies] = useCookies(['JWTToken', 'user']);

  useEffect(() => {
    const jwtToken = cookies.JWTToken, user = cookies.user

    if (jwtToken && user) {
      dispatch(validateAuthentication(user.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path={'/'} element={<HomeComponent/>}/>
          <Route path={'*'} element={<AppWrapper/>}>
              <Route
                path='profile'
                element={
                  <PrivateRoute>
                    <ProfileComponent />
                  </PrivateRoute>
                }
              >
                <Route path='' element={<ProfileHomeComponent />} />
                <Route path='orders' element={<OrdersComponent />} />
                <Route path='payments' element={<PaymentsComponent />} />
                <Route path='address' element={<AddressComponent />} />
                <Route path='settings' element={<SettingsComponent />} />
              </Route>
              <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </Router>
      <Notification />
    </ThemeProvider>
  );
}

export default App;
