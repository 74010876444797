import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(({ palette, typography, customBorders, customShadows }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 32,
    },
    closeIcon: {
        '&.MuiIconButton-root': {
            position: 'absolute',
            top: 20,
            right: 20,
        }
    },
    locationSearch: {
        display: 'flex',
        alignItems: 'center',
        border: customBorders[0],
        borderRadius: 8,
        boxShadow: customShadows[2],
        padding: '10px 15px',
        margin: '2rem 0',
        width: 500,
        maxWidth: '100%',
    },
    searchInput: {
        margin: '0 8px',
        flexGrow: 1,
        '& .MuiInputBase-input': {
            ...typography.subtitle3,
            textAlign: 'start',
        }
    },
    currentLocation: {
        '&.MuiIconButton-root': {
            color: palette.primary.main,
            padding: 0,
            marginLeft: 8,
            '&:hover': {
                backgroundColor: 'inherit',
            }
        }
    },
    citiesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0rem 3rem 1rem 3rem',
        '& h6': {
            marginBottom: '1rem',
        }
    },
    topCities: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    }
}))