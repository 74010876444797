import makeStyles from "@mui/styles/makeStyles"

export default makeStyles(({ palette }) => ({
    addressCard: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.5rem 1.25rem',
        border: '1px solid #DCDFE7',
        borderRadius: 8,
        height: '100%',
        '& .Location': {
            display: 'flex',
            alignItems: 'center',
            '& > svg': {
                color: palette.shades.black1
            }
        }
    }
}))