import { useState, Fragment } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import useStyles from './InputDropdown.styles'

export default function InputDropdown({ placeholder = 'Search', loading = false, options = [], startAdornment, ...others }) {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
  
    return (
      <Autocomplete
        id="asynchronous-dropdown"
        {...others}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={(option) => option.title}
        options={options}
        loading={loading}
        className={classes.autoCompleteInput}
        renderInput={(params) => (
            <TextField
                {...params}
                placeholder={placeholder}
                InputProps={{
                    ...params.InputProps,
                    startAdornment,
                    endAdornment: (
                      <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      </Fragment>
                    ),
                  }}
            />
        )}
      />
    );
  }
  