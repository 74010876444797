import { call, put, takeLatest } from 'redux-saga/effects'
import { uploadImageAPI } from './Profile.apis'
import { UPLOAD_PROFILE_PICTURE } from './Profile.constants'
import { SET_USER_DETAILS, TRIGGER_NOTIFICATION } from '../App.constants'
import { NOTIFICATION } from 'neerbi-fe-modules/utils/constants'

function* uploadProfilePictureSaga({ payload, callback }) {
    try {
        const image = yield call(uploadImageAPI, payload)
        callback()
        yield put({ type: SET_USER_DETAILS, payload: { profileURL: image[0] } })
        yield put({ type: TRIGGER_NOTIFICATION, payload: { open: true, type: NOTIFICATION.success, message: 'Profile picture uploaded successfully' } })
    } catch (err) {
        console.error(err)
        callback()
        yield put({ type: TRIGGER_NOTIFICATION, payload: { open: true, type: NOTIFICATION.error, message: 'Failed to upload profile picture. Please try again' } })
    }
}

export default function* profileSagas() {
    yield takeLatest(UPLOAD_PROFILE_PICTURE, uploadProfilePictureSaga)
}