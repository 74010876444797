import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette }) => ({
    logo: {
        cursor: 'pointer',
    },
    location: {
        '&.MuiIconButton-root': {
            marginLeft: 20,
            color: palette.common.white
        }
    }
}))