import { Link } from 'react-router-dom'
import cn from 'classnames';

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";

import MessageOutlined from "@mui/icons-material/MessageOutlined";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import BackIcon from '@mui/icons-material/KeyboardBackspace';

import useStyles from "./Orders.styles"

const ORDERS = [
    { id: 1, service: "men grooming", count: 1, vendor: "Sweet Pixie Salon", cost: '$15', ongoing: true },
    { id: 2, service: "wash cut & style", count: 1, vendor: "Sweet Pixie Salon", cost: '$10' },
    { id: 3, service: "wash cut & style", count: 1, vendor: "Sweet Pixie Salon", cost: '$10', rating: true },
]

export default function OrdersComponent() {
    const classes = useStyles()
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                    <Link to="/profile" className="Link">
                        <IconButton href="profile" sx={{ color: "shades.black1", marginRight: 2 }}>
                            <BackIcon />
                        </IconButton>
                    </Link>
                    <Typography variant="h3" >my orders</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} className={cn(classes.ordersContainer, 'HideScrollbar')}>
                {ORDERS.map(({ id, service, count, vendor, cost, ongoing, rating }, idx) => (
                    <Box key={id} className={classes.orderCard}>
                        {ongoing && (
                            <div className="OrderBanner">
                                <Typography variant="subtitle2" lineHeight={2}>professional is on the way</Typography>
                            </div>
                        )}
                        <Box className="OrderContent">
                            <Typography variant="content2" lineHeight={2}>{count} x {service}</Typography><br />
                            <Typography variant="content5" color="text.secondary" lineHeight={2}>{vendor}</Typography>
                            <Typography variant="h4" lineHeight={2}>{cost} <Typography variant="content4" color="text.secondary">hourly</Typography></Typography>
                            {(ongoing || rating) && <Divider variant="middle" sx={{ m: '1rem 0' }} />}
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                {ongoing && (
                                    <>
                                        <Button variant="outlined" size="medium">track location</Button>
                                        <Box>
                                            <CallOutlinedIcon />
                                            <MessageOutlined />
                                        </Box>
                                    </>
                                )}
                                {!rating && !ongoing && (
                                    <Box marginTop={2}>
                                        <Button variant="outlined" size="medium">rate service</Button>
                                    </Box>
                                )}
                                {rating && (
                                    <Box>
                                        <Typography variant="subtitle1" color="text.secondary" lineHeight={2}>your rating for the service</Typography>
                                        <Box display='flex' alignItems="center">
                                            <Rating name="read-only" max={1} value={1} readOnly size="small" />&nbsp;
                                            <Typography variant='subtitle1'>4.8 | Loved it</Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Grid>
        </Grid>
    )
}