import withStyles from '@mui/styles/withStyles';
import MuiTextField from "@mui/material/TextField";

const TextField = withStyles(({ palette, typography }) => ({
    root: {
        width: '100%',

        '& .MuiFilledInput-root': {
            border: '1px solid #DCDFE7',
            borderRadius: 12,
            backgroundColor: palette.background.grey,
            ...typography.content1,

            '&.Mui-focused': {
                backgroundColor: palette.background.grey,
            },
            '&:hover': {
                border: '1px solid #DCDFE7',
                backgroundColor: palette.background.grey,
            },
            '&::before': {
                border: 'none !important',
            },
            '&::after': {
                border: 'none !important',
            }
        },
        '& .MuiInputLabel-root': {
            ...typography.subtitle1,
            top: 3,
            left: 10,
        },
        '& .MuiFilledInput-input': {
            paddingTop: 20,
            paddingLeft: 22,
            ...typography.content2
        },
    }
}))(MuiTextField)

export default TextField