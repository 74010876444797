import useStyles from './Home.styles'
import InputDropdown from 'neerbi-fe-modules/components/input-dropdown'
import OfferCardIcon1 from '../resources/images/home/offer-card-1.svg'
import OfferCardIcon2 from '../resources/images/home/offer-card-2.svg'
import PromotionIcon1 from '../resources/images/home/promotion-1.svg'
import PromotionIcon2 from '../resources/images/home/promotion-2.svg'
import MobileAppDownload from '../resources/images/home/mobile-app-download.svg'
import VendorCard from './components/VendorCard'
import OfferCard from './components/OfferCard'
import ReviewCard from './components/ReviewCard'
import Header from '../header'
import Footer from '../footer'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

const vendors = [
    { id: 1, name: 'Sweet Pixie Salon', services: ['haircut', 'styling', 'massage', 'hair-coloring'], rating: 4.8, distance: '20 - 25 min' },
    { id: 2, name: 'The Big Tease Salon', services: ['haircut', 'styling', 'massage'], rating: 4.5, distance: '25 - 30 min' },
    { id: 3, name: 'Green Salon', services: ['haircut', 'styling', 'massage', 'hair-coloring'], rating: 4.3, distance: '35 - 45 min' },
    { id: 4, name: 'Sweet Pixie Salon', services: ['haircut', 'styling', 'massage', 'hair-coloring'], rating: 4.8, distance: '20 - 25 min' },
    { id: 5, name: 'The Big Tease Salon', services: ['haircut', 'styling', 'massage', 'hair-coloring'], rating: 4.5, distance: '25 - 30 min' },
    { id: 6, name: 'Green Salon', services: ['haircut', 'styling', 'massage', 'hair-coloring'], rating: 4.3, distance: '35 - 45 min' },
]

const offers = [
    { id: 1, name: 'Nooks & Crannies', tag: 'home cleaning', Icon: OfferCardIcon1 },
    { id: 2, name: 'Meticulous Maid', tag: 'neerbie service', Icon: OfferCardIcon2 },
    { id: 3, name: 'Nooks & Crannies', tag: 'home cleaning', Icon: OfferCardIcon1 },
    { id: 4, name: 'Meticulous Maid', tag: 'neerbie service', Icon: OfferCardIcon2 },
    { id: 5, name: 'Nooks & Crannies', tag: 'home cleaning', Icon: OfferCardIcon1 },
    { id: 6, name: 'Meticulous Maid', tag: 'neerbie service', Icon: OfferCardIcon2 },
]

const reviews = [
    { id: 1, name: 'Ira Merchant', rating: 4.8, Icon: OfferCardIcon1 },
    { id: 2, name: 'Vishal Kumar', rating: 4.5, Icon: OfferCardIcon2 },
    { id: 3, name: 'Ms. Queen', rating: 4.3, Icon: OfferCardIcon1 },
    { id: 4, name: 'Ira Merchant', rating: 4.8, Icon: OfferCardIcon1 },
    { id: 5, name: 'Vishal Kumar', rating: 4.5, Icon: OfferCardIcon2 },
    { id: 6, name: 'Ms. Queen', rating: 4.3, Icon: OfferCardIcon1 },
]

export default function Home() {
    const classes = useStyles()
    return (
        <>
            <Header isFixed={true} />
            <Box
                sx={{
                    marginTop: { xs: '56px', sm: '56px', md: '72px' },
                    height: { xs: 'calc(100% - 56px)', sm: 'calc(100% - 56px)', md: 'calc(100% - 72px)' },
                    overflow: 'auto'
                }}
            >
                <Box className={classes.homeSection} sx={{ position: 'relative' }}>
                    <Grid container justifyContent='center'>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.truckIcon} sx={{ display: { xs: 'none', md: 'flex' } }} />
                        <Grid container justifyContent='center' sx={{ marginTop: '15%', marginBottom: '25px' }}>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{ margin: 'auto' }}>
                                <Typography variant='h1' textAlign='center'>
                                    Neerbi connects YOU with mobile professionals who are working in your neighborhood RIGHT NOW!
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} sm={8} md={4} lg={3} xl={3}>
                            <InputDropdown
                                placeholder="let's get your job done TODAY!"
                                noOptionsText='No services found'
                                options={[]}
                                loading={false}
                                startAdornment={<SearchIcon sx={{ color: 'common.grey', height: 24, width: 24, margin: '0 5px' }} />}
                            />
                            <Typography variant='subtitle1' sx={{ color: 'text.secondary', m: '10px' }}>
                                search for services and offers
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={1} xl={1}>
                            <Button variant='contained' size='large' sx={{ margin: '0px 10px' }}>search</Button>
                        </Grid>
                        <Grid item className={classes.technicianIcon} sx={{ display: { xs: 'none', md: 'flex' } }} />
                    </Grid>
                </Box>
                <Grid container justifyContent='center'>
                    <Typography variant='h1' textAlign='center'>
                        currently working near you
                    </Typography>
                    <Box display='flex' className='HideScrollbar' sx={{ margin: '20px 0px 20px 120px', overflow: 'auto' }}>
                        {vendors.map((vendor) => <VendorCard key={vendor.id} {...vendor} />)}
                    </Box>
                </Grid>
                <Grid container justifyContent='center'>
                    <Typography variant='h1' textAlign='center'>
                        Best offers available to you
                    </Typography>
                    <Box display='flex' className='HideScrollbar' sx={{ margin: '20px 0px 20px 120px', overflow: 'auto' }}>
                        {offers.map((offer) => <OfferCard key={offer.id} {...offer} />)}
                    </Box>
                </Grid>
                <Box className={classes.promotions}>
                    <img src={PromotionIcon1} alt='services' />
                    <img src={PromotionIcon2} alt='offer' />
                </Box>
                <Box className={classes.downloadAppImage} sx={{ backgroundImage: `url(${MobileAppDownload})` }} />
                <Grid container justifyContent='center' className={classes.reviewContainer} sx={{ padding: '72px 0px 72px 120px' }} bgcolor='background.grey'>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={4} className='ReviewDesc'>
                        <Typography variant='h1' className='ReviewHeader'>customer safety is our priority</Typography>
                        <Typography variant='content1' color='common.grey'>what customers are saying about our safety</Typography>
                        <Box sx={{ margin: '20px 0' }}>
                            <IconButton aria-label="left" size="large" className='ReviewSlider'>
                                <ChevronLeft fontSize="inherit" />
                            </IconButton>
                            <IconButton aria-label="right" size="large" className='ReviewSlider'>
                                <ChevronRight fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={8} className='HideScrollbar' sx={{ display: 'flex', overflow: 'auto' }} >
                        {reviews.map((review) => <ReviewCard key={review.id} {...review} />)}
                    </Grid>
                </Grid>
                <Footer />
            </Box>
        </>
    )
}