import { Link } from "react-router-dom";

export default function PageNotFound() {
    return (
        <div>
            404 Page not found
            <Link to='/'>Go to Home</Link>
        </div>
    )
}
