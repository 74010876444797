import axios from 'axios';
import { replaceQueryParams } from 'neerbi-fe-modules/utils/utils';

export const generateOTPAPI = (payload) => axios.post('/customer/login', payload).then((res) => res.data.success)

export const verifyOTPAPI = (payload) => axios.post('/customer/login/verify', payload).then((res) => res.data.success)

export const getUserDetailsAPI = (id) =>
    axios.get(replaceQueryParams('/customer/{id}', { id })).then(res => res.data.success);

export const updateUserDetailsAPI = (payload, params) =>
    axios.patch(replaceQueryParams('/customer/{id}', params), payload).then((res) => res.data.success)