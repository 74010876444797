import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(({ palette }) => ({
    otpContainer: {
        margin: '20px 0',
    },
    resendButton: {
        '&.MuiButton-root': {
            padding: 2,
            margin: '5px 0px',
            '&:hover': {
                backgroundColor: 'unset',
            }
        }
    },
    profileIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 35,
        width: 35,
        backgroundColor: palette.primary.main,
        padding: 5,
        borderRadius: 25,
        boxShadow: '0px 3px 24px rgba(82, 153, 250, 0.6)',
        border: '1px solid #FFFFFF',
        cursor: 'pointer',
        position: 'absolute',
        bottom: -17,
        left: 'calc(50% - 17px)',
    },
    userDetailsContainer: {
        marginTop: '2rem',
    },
    phoneInputField: {
        margin: '3rem 0',
        '& .PhoneInputCountryIconUnicode': {
            fontSize: 30,
        },
        '& .PhoneInputCountry': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: 12,
            paddingLeft: 12,
            paddingRight: 6,
        }
    },
}))