import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(() => ({
    masterCardIcon: {
        height: '2rem',
        width: '2rem',
    },
    visaCardIcon: {
        height: '1rem',
        width: '3rem',
    },
    container: {
        height: '100%',
    },
    paymentsContainer: {
        height: 'calc(100% - 45px)',
        overflow: 'auto',
        '& .CardSection': {
            margin: '2rem 0'
        },
    },
    paymentCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1rem !important',
        padding: '1rem',
        border: '1px solid #DCDFE7',
        borderRadius: 12,

        '& .CardDetails': {
            display: 'flex',
            alignItems: 'center',
            '& > img': {
                marginRight: '1rem',
            },
            '& > div > *': {
                margin: '2px 0',
            }
        },
        '& .PrimaryCard': {
            width: 'fit-content'
        },
    }
}))