import useStyles from '../Home.styles'

import Typography from '@mui/material/Typography';

export default function OfferCard({ id, name, tag, Icon }) {
    const classes = useStyles()

    return (
        <div className={classes.offerCard} style={{ background: `url(${Icon}) no-repeat` }}>
            <div>
                <Typography className='OfferTag' variant='subtitle2'>{tag}</Typography>
                <Typography className='OfferName' variant='h4' color='common.white'>{name}</Typography>
            </div>
        </div>
    )
}