import { Link } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from "@mui/material/Typography";
import Checkbox from '@mui/material/Checkbox'

import BackIcon from '@mui/icons-material/KeyboardBackspace';
import AddIcon from '@mui/icons-material/AddCircle';
import PlaceIcon from '@mui/icons-material/PlaceOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRightOutlined';
import CheckedIcon from '@mui/icons-material/CheckCircle';
import UncheckedIcon from '@mui/icons-material/CircleOutlined';

import useStyles from "./Address.styles"

const ADDRESS = [
    { id: 1, location: 'Locust Court LA', address: '685 Stiles Street Locust Court LA', selected: true },
    { id: 2, location: 'Bangalore Karnataka 560097', address: '#19 Malleshwaram Bangalore Karnataka India 560097', selected: false }
]

export default function AddressComponent() {
    const classes = useStyles()
    return (
        <Grid container>
            <Grid item xs={12} marginBottom={4}>
                <Box display="flex" alignItems="center">
                    <Link to="/profile" className="Link">
                        <IconButton href="profile" sx={{ color: "shades.black1", marginRight: 2 }}>
                            <BackIcon />
                        </IconButton>
                    </Link>
                    <Typography variant="h3" >my addresses</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {ADDRESS.map(({ id, location, address, selected }) => (
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={id}>
                            <Box className={classes.addressCard}>
                                <Box>
                                    <Box className="Location">
                                        <PlaceIcon fontSize='12px' />
                                        <Typography variant='content1'>{location}</Typography>
                                        <ArrowRightIcon fontSize='12px' />
                                    </Box>
                                    <Typography variant='content4' color='text.secondary'>{address}</Typography>
                                </Box>
                                <Checkbox
                                    checked={selected}
                                    onChange={() => { }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    checkedIcon={<CheckedIcon />}
                                    icon={<UncheckedIcon />}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <IconButton color="primary" aria-label="add address" component="span" size='large'>
                    <AddIcon fontSize="inherit" />
                </IconButton>
                <Typography variant="content5">add new address</Typography>
            </Grid>
        </Grid>
    )
}