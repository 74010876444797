import { useRef, useEffect, useState, useCallback } from "react"

export function useTimer(timer) {
    const timerRef = useRef(null)
    const [initialised, setInitialisation] = useState(true)
    const [timeLeft, setTimeLeft] = useState(timer)

    useEffect(() => {
        if (initialised) {
            clearInterval(timerRef.current)
            setTimeLeft(timer)
            timerRef.current = setInterval(() => {
                setTimeLeft((curVal) => {
                    const newTime = curVal - 1
                    if (newTime <= 0) {
                        clearInterval(timerRef.current)
                    }
                    return newTime
                })
            }, 1000)
        }
        
        return () => clearInterval(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialised])

    useEffect(() => {
        if (!timeLeft) {
            setInitialisation(false)
        }
    }, [timeLeft])

    const minElapsed = Math.floor(timeLeft / 60)
    const secElapsed = timeLeft - (minElapsed * 60)

    const resetTimer = useCallback(() => {
        clearInterval(timerRef.current)
        setInitialisation(true)
    }, [])

    return [("0" + minElapsed).slice(-2), ("0" + secElapsed).slice(-2), resetTimer]
}