import useStyles from '../Home.styles'
import StarIcon from '../../resources/images/home/star.svg'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

const defaultReview = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet'

export default function ReviewCard({ name, rating, review = defaultReview, avatar=`../../resources/images/default-avatar.svg` }) {
    const classes = useStyles()

    return (
        <div className={classes.reviewCard}>
            <Box display='flex' justifyContent='space-between'>
                <Box display='flex' alignItems='center'>
                    <Avatar src={avatar}/>&nbsp;&nbsp;&nbsp;
                    <Typography variant='h4'>{name}</Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                    <img src={StarIcon} alt="*" />&nbsp;
                    <Typography variant='h6'>{rating}</Typography>
                </Box>
            </Box><br/>
            <Typography variant='content3'>{review}</Typography>
        </div>
    )
}