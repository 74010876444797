import makeStyles from "@mui/styles/makeStyles";

import TruckIcon from "../resources/images/home/truck.svg"
import TechnicianIcon from "../resources/images/home/technician.svg"

export default makeStyles(({ palette, breakpoints, customShadows }) => ({
    homeSection: {
        height: '100%',
    },
    truckIcon: {
        position: 'absolute',
        bottom: 0,
        height: 415,
        width: '100%',
        background: `url(${TruckIcon}) no-repeat`,
    },
    technicianIcon: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: 415,
        width: '100%',
        background: `url(${TechnicianIcon}) no-repeat`,
        backgroundPositionX: 'right',
    },
    vendorCard: {
        border: '1px solid #E1E3EA',
        borderRadius: 12,
        padding: 24,
        margin: '20px 10px',
        minHeight: 183,
        minWidth: 388,
        cursor: 'pointer',
        [breakpoints.down('sm')]: {
            height: 146,
            width: 271,
            minHeight: 146,
            minWidth: 271,
        },
        '& .ServiceLabel': {
            color: palette.common.grey,
            lineHeight: '2rem',
        },
        '& .ServiceContent': {
            marginTop: 28,
        },
        '& .ServiceSubtitle': {
            marginBottom: 8,
            color: palette.common.grey,
        }
    },
    offerCard: {
        position: 'relative',
        width: 285,
        height: 280,
        minHeight: 280,
        minWidth: 285,
        borderRadius: 12,
        margin: '20px 10px',
        padding: '20px 10px',
        cursor: 'pointer',
        [breakpoints.down('sm')]: {
            height: 169,
            width: 172,
            minHeight: 169,
            minWidth: 172,
        },
        '& > div': {
            position: 'absolute',
            bottom: 25,
            left: 20,
        },
        '& .OfferTag': {
            backgroundColor: palette.secondary.main,
            padding: 4,
            color: palette.text.primary,
            width: 'fit-content'
        },
        '& .OfferName': {
            marginTop: 20,
            color: palette.common.white,
        }
    },
    promotions: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 120px',
        height: 260,
        '& img': {
            flexGrow: 1
        }
    },
    downloadAppImage: {
        display: 'flex',
        margin: '50px 120px',
        height: 412,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    reviewContainer: {
        '& .ReviewDesc': {
            padding: '0 20px',

            '& .ReviewHeader': {
                marginBottom: 16,
            },

            '& .ReviewSlider': {
                height: '48px',
                width: '48px',
                fontSize: '48px',
                margin: '0 20px'
            }
        },
    },
    reviewCard: {
        borderRadius: 12,
        padding: 30,
        margin: '0 10px',
        width: 388,
        maxHeight: 224,
        minWidth: 388,
        backgroundColor: palette.common.white,
        boxShadow: customShadows[1],
        [breakpoints.down('sm')]: {
            height: 141,
            width: 301,
            minHeight: 141,
            minWidth: 301,
        },
    }
}))