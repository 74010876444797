import { forwardRef } from 'react'
import PhoneInput from 'react-phone-number-input'

import Typography from '@mui/material/Typography'

import useStyles from '../Login.styles'
import 'react-phone-number-input/style.css'

import DownArrowIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InputField from 'neerbi-fe-modules/components/input-field'

const CustomInput = forwardRef((props, ref) => {
    return (
      <InputField
        {...props}
        inputRef={ref}
        id="mobile-input"
        label="phone number"
        variant="filled"
      />
    )
})

export default function LoginNumber({ value, onChange }) {
    const classes = useStyles()

    return (
        <>
            <Typography variant="h2">Let's get started connecting with the BEST local pros!</Typography>
            <Typography variant="content4" color="text.secondary">login or create new account</Typography>
            <PhoneInput
                id="mobile-input"
                label="phone number"
                placeholder="phone number"
                defaultCountry="IN"
                countrySelectProps={{ unicodeFlags: true, arrowComponent: DownArrowIcon }}
                value={value}
                onChange={onChange}
                inputComponent={CustomInput}
                className={classes.phoneInputField}
            />
            <Typography variant="subtitle1" color="text.secondary">
                we'll send you otp on your number to confirm your account. standard sms and data charges apply
            </Typography>
        </>
    )
}