import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import { common } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';

import LocationIcon from '@mui/icons-material/LocationOn'
import ArrowRight from '@mui/icons-material/ArrowRight'

import useStyles from "./Header.styles"
import Logo from '../resources/images/logo.svg'
import DefaultAvatar from 'neerbi-fe-modules/resources/images/default-avatar.svg'
import Location from './location'
import Login from './login'
import { Protected } from 'neerbi-fe-modules/components/private-access'
import { logoutApp } from '../App.actions';

const Header = ({ isFixed = false }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isLocationDialog, setLocationDialog] = useState(false)
  const [isLoginDialog, setLoginDialog] = useState(false)
  const user = useSelector(state => state.app.userDetails)
  const isAuthenticated = useSelector(state => state.app.isAuthenticated)

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLocationDialog = useCallback(() => setLocationDialog(true), []);

  const handleCloseLocationDialog = useCallback(() => setLocationDialog(false), []);

  const handleOpenLoginDialog = useCallback(() => setLoginDialog(true), []);

  const handleCloseLoginDialog = useCallback(() => setLoginDialog(false), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLogoutClick = useCallback(() => dispatch(logoutApp()), []);

  return (
    <>
      <AppBar position={isFixed ? "fixed" : "relative"} sx={{ padding: '0 60px', height: { xs: '56px', sm: '56px', md: '72px' } }}>
        <Toolbar disableGutters sx={{ minHeight: { xs: '56px', sm: '56px', md: '72px' } }}>
          <Box display='flex' alignItems='center'>
            <Link to="/">
              <img className={classes.logo} src={Logo} alt="Neerbi" />
            </Link>
            <IconButton size='small' className={classes.location} onClick={handleOpenLocationDialog}>
              <LocationIcon fontSize='inherit' />
              <Typography variant='content4' color='inherit'>560097</Typography>
              <ArrowRight fontSize='inherit' />
            </IconButton>
          </Box>
          <Box display='flex' alignItems='center' flexGrow={1} justifyContent='flex-end'>
            <Typography sx={{ display: { xs: 'none', md: 'flex' }, color: 'common.white', marginRight: '20px' }} variant='content6'>become a pro</Typography>
            <IconButton onClick={handleOpenUserMenu} disableRipple sx={{ p: '5px', borderRadius: '10px', border: `1px solid`, borderColor: 'common.white' }}>
              <MenuIcon sx={{ color: common.white, width: 25, height: 25 }} />
              <Avatar
                alt="Profile pic"
                sx={{ color: common.white, width: 30, height: 30, margin: '0 5px', fontSize: '14px' }}
                src={user.profileURL || DefaultAvatar}
              >
              </Avatar>
            </IconButton>
            <Menu
              sx={{ mt: '45px', minWidth: '242px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              onClick={handleCloseUserMenu}
            >
              {
                !isAuthenticated ? (
                  <MenuItem onClick={handleOpenLoginDialog}>
                    <Typography textAlign="center">login / signup</Typography>
                  </MenuItem>
                ) : null
              }
              <Protected>
                <Link to={'/profile'} className="Link">
                  <MenuItem>
                    <Typography textAlign="center">my profile</Typography>
                  </MenuItem>
                </Link>
              </Protected>
              <MenuItem>
                <Typography textAlign="center">how it works</Typography>
              </MenuItem>
              <Protected>
                <MenuItem onClick={handleLogoutClick}>
                  <Typography textAlign="center">logout</Typography>
                </MenuItem>
              </Protected>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Location open={isLocationDialog} onClose={handleCloseLocationDialog} />
      <Login open={isLoginDialog} onClose={handleCloseLoginDialog}/>
    </>
  );
};
export default Header;
