import { call, put, takeLatest } from 'redux-saga/effects'
import { Cookies } from 'react-cookie'

import { SET_AUTHENTICATION, SET_USER_DETAILS, VALIDATE_AUTHENTICATION } from './App.constants'
import { getUserDetailsAPI } from './header/login/Login.apis'

function* validateAuthenticationSaga({ payload }) {
    try {
        const userDetails = yield call(getUserDetailsAPI, payload)
        const cookies = new Cookies()
        const token = cookies.get('JWTToken')

        yield put({ type: SET_AUTHENTICATION, payload: { jwt: token, isAuthenticated: true } })
        yield put({ type: SET_USER_DETAILS, payload: userDetails })
    } catch (err) {
        console.error('User is unauthorized')
        yield put({ type: SET_AUTHENTICATION, payload: { jwt: '', isAuthenticated: false } })
        yield put({ type: SET_USER_DETAILS, payload: {} })
    }
}

function* appSagas() {
    yield takeLatest(VALIDATE_AUTHENTICATION, validateAuthenticationSaga)
}

export default appSagas;