import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(({ palette }) => ({
    container: {
        height: '100%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    settingsContainer: {
        height: 'calc(100% - 45px)',
        overflow: 'auto',
        '& .SettingsItem': {
            margin: '2rem 0'
        }
    },
}))