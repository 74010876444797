const palette = {
    primary: {
        main: '#2E66F3',
        contrastText: '#FFFFFF',
    },
    secondary: {
        main: '#FFC400',
        contrastText: '#363636',
    },
    error: {
        main: '#FB3256',
        contrastText: '#FFFFFF',
    },
    common: {
        white: '#FFFFFF',
        black: '#000000',
        grey: '#7A8B9C'
    },
    shades: {
        black1: '#363636',
        grey1: '#7A8B9C',
        grey2: '#DCDFE7',
        grey3: '#F3F4F7',
        cyan1: '#7AF1FB'
    },
    text: {
        primary: '#363636',
        secondary: '#7A8B9C',
        disabled: '#DCDFE7'
    },
    background: {
        grey: '#F3F4F7'
    }
}

export default palette