import { Link } from 'react-router-dom'
import cn from 'classnames';

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import LogoutIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';

import useStyles from "./Settings.styles"
import Divider from '@mui/material/Divider';

export default function SettingsComponent() {
    const classes = useStyles()
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Box className={classes.header}>
                    <Link to="/profile" className="Link">
                        <IconButton href="profile" sx={{ color: "shades.black1", marginRight: 2 }}>
                            <BackIcon />
                        </IconButton>
                    </Link>
                    <Typography variant="h3">settings</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} className={cn(classes.settingsContainer, 'HideScrollbar')}>
                <Grid container>
                    <Grid item xs={12} className="SettingsItem">
                        <Box display="flex" alignItems="center" marginBottom={1}>
                            <DeleteIcon sx={{ color: "shades.black1", fontSize: '1.2rem', marginRight: 1 }} />
                            <Typography variant='h6'>delete account</Typography>
                        </Box>
                        <Typography variant="subtitle1" color="text.secondary">
                            you will lost all your order history and payment details
                        </Typography>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12} className="SettingsItem">
                        <Box display="flex" alignItems="center">
                            <LogoutIcon sx={{ color: "error.main", fontSize: '1.2rem', marginRight: 1 }}/>
                            <Typography variant='h6' color="error.main">logout account</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}