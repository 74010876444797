import { useDispatch, useSelector } from 'react-redux'

import styled from '@mui/styles/styled'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';

import { triggerNotification } from '../../../App.actions'
import { NOTIFICATION } from '../../utils/constants';

const Notification = styled(Snackbar)(() => ({

}))

function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
}

export default function NotificationComponent() {
    const dispatch = useDispatch()
    const { open, message, type } = useSelector((state) => state.app.notification)

    const handleClose = () => dispatch(triggerNotification({ open: false }))

    return (
        <Notification
            open={open}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                '& .MuiAlert-root': {
                    minWidth: '275px'
                }
            }}
        >
            <Alert severity={NOTIFICATION[type]} elevation={6} variant="filled">{message}</Alert>
        </Notification>
    )
}
