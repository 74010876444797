import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"

import useStyles from "./Footer.styles"
import AppStoreDownloadIcon from "../resources/images/app-store-download.svg"

export default function Footer() {
    const classes = useStyles()
    return (
        <Grid container bgcolor='common.black' className={classes.container}>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Typography variant='h4' color='common.white'>company</Typography>
                <Link href="#" underline='hover'>about neerbi</Link><br/>
                <Link href="#" underline='hover'>terms & conditions</Link><br/>
                <Link href="#" underline='hover'>privacy policy</Link><br/>
                <Link href="#" underline='hover'>register as professtional</Link>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Typography variant='h4' color='common.white'>support</Typography>
                <Link href="#" underline='hover'>help center</Link><br/>
                <Link href="#" underline='hover'>sitemap</Link>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Typography variant='h4' color='common.white'>social</Typography>
                <Link href="#" underline='hover'>instagram</Link><br/>
                <Link href="#" underline='hover'>twitter</Link><br/>
                <Link href="#" underline='hover'>facebook</Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant='h4' color='common.white' textAlign='right'>download the app now</Typography>
                <img src={AppStoreDownloadIcon} alt="Download from app store" className={classes.downloadIcon}/>
            </Grid>
            <Divider className={classes.divider} />
            <Box className={classes.copyright}>copyright &copy; neerbi.com India Private Limited. all rights reserved.</Box>
        </Grid>
    )
}