import axios from 'axios';
import { Cookies } from 'react-cookie';

export default function initializeAxios() {
   axios.defaults.baseURL = process.env.REACT_APP_SERVICE
   axios.interceptors.request.use(
      (req) => {
         const cookies = new Cookies()
         const token = cookies.get('JWTToken')

         if (token) {
            req.headers.authorization = token;
         }
         return req;
      },
      (err) => {
         return Promise.reject(err);
      }
   );
   axios.interceptors.response.use(
      (res) => {
         return res;
      },
      (err) => {
         return Promise.reject(err);
      }
   );
}