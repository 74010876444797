import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, typography }) => ({
    container: {
        height: 240,
        padding: '20px 117px',
        color: palette.common.white,

        '& div h4': {
            marginBottom: 10,
        },
        '& a': {
            ...typography.subtitle1,
            color: '#DCDFE7',
            lineHeight: 2,
        },
        '& hr': {
            width: '100%',
            borderColor: '#474748',
        },
    },
    downloadIcon: {
        float: 'right'
    },
    copyright: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '12px',
        textAlign: 'center',
    }
}))