import { call, put, takeLatest } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';

import { generateOTPAPI, updateUserDetailsAPI, verifyOTPAPI } from './Login.apis'
import { GENERATE_OTP_ACTION, GET_USER_DETAILS, UPDATE_USER_DETAILS, VERIFY_OTP_ACTION } from './Login.constants'
import { SET_AUTHENTICATION, SET_USER_DETAILS, TRIGGER_NOTIFICATION } from '../../App.constants'
import { NOTIFICATION } from 'neerbi-fe-modules/utils/constants';

function* generateOTPSaga({ payload, callback }) {
    try {
        const nonce = yield call(generateOTPAPI, payload)
        yield put({ type: TRIGGER_NOTIFICATION, payload: { open: true, type: NOTIFICATION.success, message: 'OTP generated successfully' } })
        callback({ nonce }, null)
    } catch (err) {
        yield put({ type: TRIGGER_NOTIFICATION, payload: { open: true, type: NOTIFICATION.error, message: 'Failed to generate OTP. Please try again !' } })
        callback(null, null)
        console.log('error', err)
    }
}

function* verifyOTPSaga({ payload, callback }) {
    try {
        const response = yield call(verifyOTPAPI, payload)
        const cookies = new Cookies()

        if (!cookies.get('JWTToken')) {
            cookies.set('JWTToken', response.authentication)
            cookies.set('user', response.customer)
        }
        yield put({ type: SET_AUTHENTICATION, payload: { jwt: response.authentication, isAuthenticated: true } })
        yield put({ type: SET_USER_DETAILS, payload: response.customer })
        yield put({ type: TRIGGER_NOTIFICATION, payload: { open: true, type: NOTIFICATION.success, message: 'Login successful' } })
        callback(response, null)
    } catch (err) {
        yield put({ type: TRIGGER_NOTIFICATION, payload: { open: true, type: NOTIFICATION.error, message: 'Invalid OTP' } })
        callback(null, null)
        console.log('error', err)
    }
}

function* getUserDetailsSaga({ payload }) {
    try {
        const response = yield call(updateUserDetailsAPI, payload, { id: payload.id })

        yield put({ type: SET_USER_DETAILS, payload: response })
    } catch (err) {
        console.log('error', err)
        yield put({ type: SET_USER_DETAILS, payload: {} })
    }
}

function* updateUserDetailsSaga({ payload, callback }) {
    try {
        const response = yield call(updateUserDetailsAPI, payload, { id: payload.id })

        yield put({ type: SET_USER_DETAILS, payload: response })
        yield put({ type: TRIGGER_NOTIFICATION, payload: { open: true, type: NOTIFICATION.success, message: 'User details updated successfully' } })
        callback(response, null)
    } catch (err) {
        callback(null, null)
        yield put({ type: TRIGGER_NOTIFICATION, payload: { open: true, type: NOTIFICATION.error, message: 'Failed to update user details' } })
        console.log('error', err)
    }
}

function* loginSagas() {
    yield takeLatest(GENERATE_OTP_ACTION, generateOTPSaga)
    yield takeLatest(VERIFY_OTP_ACTION, verifyOTPSaga)
    yield takeLatest(GET_USER_DETAILS, getUserDetailsSaga)
    yield takeLatest(UPDATE_USER_DETAILS, updateUserDetailsSaga)
}

export default loginSagas;