import palette from "./palette"
import typography from "./typography"

const components = {
    MuiButton: {
        styleOverrides: {
            sizeSmall: {

            },
            sizeMedium: {
                ...typography.h6,
                padding: '0.625rem 1rem',
            },
            sizeLarge: {
                minHeight: 48,
            },
            root: {
                textTransform: 'none',
                borderRadius: 4,
            }
        }
    },
    MuiTypography: {
        styleOverrides: {
            root: {
                color: palette.text.primary,
            },
        }
    }
}

export default components