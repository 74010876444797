import { useState } from 'react'
import { useDispatch } from 'react-redux';

import styled from '@mui/styles/styled';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';

import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import NearMeIcon from '@mui/icons-material/NearMe';

import useStyles from './Location.styles'
import { NOTIFICATION } from 'neerbi-fe-modules/utils/constants'
import { triggerNotification } from '../../App.actions'

function LocationSearch() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [coordinates, setCoordinates] = useState({})

    const onUseCurrentLocation = () => {
        const success = (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            setCoordinates({ latitude, longitude })
        }

        const error = () => {
            dispatch(triggerNotification({ open: true, type: NOTIFICATION.error, message: 'Failed to retrieve your current location' }))
        }

        if (!navigator.geolocation) {
            dispatch(triggerNotification({ open: true, type: NOTIFICATION.error, message: 'Geolocation is not supported by your browser' }))
        } else {
            navigator.geolocation.getCurrentPosition(success, error);
        }

    }

    return (
        <Box className={classes.locationSearch}>
            <LocationIcon fontSize='small' color='common.grey'/>
            <InputBase
                placeholder="enter your city zipcode"
                inputProps={{ 'aria-label': 'search city' }}
                className={classes.searchInput}
            />
            <Divider sx={{ height: 18, m: 0.5 }} orientation="vertical" />
            <IconButton className={classes.currentLocation} size='small' disableFocusRipple={true} disableTouchRipple={true}>
                <NearMeIcon fontSize='inherit' color='inherit' />
                <Typography
                    variant='subtitle2'
                    color='inherit'
                    sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, ml: 1 }}
                >
                    use my current location
                </Typography>
            </IconButton>
        </Box>
    )
}

const City = styled('div')(({ theme: { palette, customBorders } }) => ({
    border: customBorders[0],
    background: palette.common.white,
    borderRadius: 8,
    padding: '5px 40px',
    margin: 10,
    cursor: 'pointer'
}))

const CITIES = [
    { id: 1, name: 'new york', value: '' },
    { id: 2, name: 'los angeles', value: '' },
    { id: 3, name: 'chicago', value: '' },
    { id: 4, name: 'san diego', value: '' },
    { id: 5, name: 'seattle', value: '' },
    { id: 6, name: 'philadelphia', value: '' },
]

export default function Location({ open, onClose }) {
    const classes = useStyles()
    return (
        <Drawer
            anchor={'top'}
            open={open}
            onClose={onClose}
        >
            <Box
                role="location"
                className={classes.container}
            >
                <Typography variant="h1">pick a city</Typography>
                <IconButton aria-label="close" className={classes.closeIcon} onClick={onClose}>
                    <CloseIcon fontSize="inherit" color='inherit' />
                </IconButton>
                <Typography variant="content1" color="text.secondary" sx={{ mt: 1 }}>to find neerby professionals around you</Typography>
                <LocationSearch />
                <Box className={classes.citiesContainer}>
                    <Typography variant="h6">top cities</Typography>
                    <Box className={classes.topCities}>
                        {CITIES.map(({ id, name }) => (
                            <City key={id}>
                                <Typography variant="subtitle3">{name}</Typography>
                            </City>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Drawer>
    )
}
