import { Fragment } from "react";
import { Outlet, Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import useStyles from "./Profile.styles"
import DownloadImage from "../resources/images/profile/profile_download.svg"
import OrdersTabIcon from "../resources/images/profile/orders_tab.svg"
import PaymentsTabIcon from "../resources/images/profile/payments_tab.svg"
import AddressTabIcon from "../resources/images/profile/address_tab.svg"
import SettingsTabIcon from "../resources/images/profile/settings_tab.svg"
import InviteTabIcon from "../resources/images/profile/invite_tab.svg"
import RateTabIcon from "../resources/images/profile/rate_tab.svg"


const TABS = [
    { id: 1, tab: 'my orders', desc: 'ongoing order', url: 'orders', isTab: true, Icon: OrdersTabIcon },
    { id: 2, tab: 'payment option', desc: 'saved cards and other payment method', url: 'payments', isTab: true, Icon: PaymentsTabIcon },
    { id: 3, tab: 'addresses', desc: 'saved addresses for hassle-free checkout', url: 'address', isTab: true, Icon: AddressTabIcon },
    { id: 4, tab: 'settings', desc: 'update account preferences', url: 'settings', isTab: true, Icon: SettingsTabIcon },
    { id: 5, tab: 'invite friends on app', desc: 'invite friends on app', isTab: false, Icon: InviteTabIcon },
    { id: 6, tab: 'rate app on app store', desc: 'rate app on app store', isTab: false, Icon: RateTabIcon },
]

export function ProfileHomeRightSection() {
    const classes = useStyles()
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} display="flex">
                    <img src={DownloadImage} alt="download app" className={classes.downloadApp} />
                </Grid>
            </Grid>
            <Grid container rowSpacing={2} columnSpacing={2}>
                {TABS.map(({ id, tab, Icon, url }) => (
                    <Grid item key={id} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Link to={`/profile/${url}`} className="Link">
                            <Box className={classes.tabCard}>
                                <img src={Icon} alt={tab} />
                                <Typography variant="h6" textAlign="center">{tab}</Typography>
                            </Box>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default function ProfileComponent() {
    const classes = useStyles()
    return (
        <Grid container className={classes.container} columnSpacing={5}>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                <Box className={classes.leftNavbar}>
                    <Box display="flex" alignItems="center" justifyContent="center" padding="2rem 1rem">
                        <Avatar sizes="72" />
                        <Box display='flex' flexDirection="column" alignItems='flex-start' className="ProfileDetails">
                            <Typography variant="h6">Hi Deepak</Typography>
                            <Typography variant="subtitle1" color="text.secondary">+91-9456739893</Typography>
                            <Box display='flex' alignItems="center">
                                <Rating name="read-only" max={1} value={1} readOnly size="small" />&nbsp;
                                <Typography variant='subtitle1'>{4.8}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <List className="ProfileTabs">
                        {TABS.map(({ id, tab, isTab, desc, url }) => isTab && (
                            <Fragment key={id}>
                                <Divider variant="middle" />
                                <ListItem>
                                    <Link to={`/profile/${url}`} className="Link">
                                        <Typography variant="content5">{tab}</Typography>
                                        <Typography variant="subtitle1" color="text.secondary">{desc}</Typography>
                                    </Link>
                                </ListItem>
                            </Fragment>
                        ))}
                    </List>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={9} xl={9} className={classes.rightSection}>
                <Outlet />
            </Grid>
        </Grid>
    )
}