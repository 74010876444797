// keyCode constants
export const KEYCODES = {
    'BACKSPACE': 8,
    'LEFT_ARROW': 37,
    'RIGHT_ARROW': 39,
    'DELETE': 46,
    'SPACEBAR': 32,
}

export const NOTIFICATION = {
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning'
}