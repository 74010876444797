import { useMemo, useState } from 'react'

import styled from '@mui/styles/styled'

// keyCode constants
export const KEYCODES = {
    'BACKSPACE': 8,
    'LEFT_ARROW': 37,
    'RIGHT_ARROW': 39,
    'DELETE': 46,
    'SPACEBAR': 32,
}

const OTPInput = styled('input')(({ theme: { customBorders } }) => ({
    width: '3rem',
    height: '3rem',
    padding: '1rem',
    textAlign: 'center',
    border: customBorders[0],
    borderRadius: 14,
    marginRight: 20,
    '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        margin: 0,
    },
}));

export default function Otp({ inputLength = 4, onChange }) {
    const [otp, setOtp] = useState([...new Array(inputLength)].fill(''))
    const inputs = useMemo(() => ([...new Array(inputLength)]), [inputLength])

    // Focus on input by index
    const focusInput = (idx) => {
        const element = document.querySelector(`#otp-${idx}`)

        if (element)  {
            element.focus()
        }
    };

    const handleChange = (evt, idx) => {
        const val = evt.target.value.slice(-1)
        changeCodeAtFocus(idx, val)
        if (val.trim() !== '') {
            focusInput(idx+1)
        }
    }

    const changeCodeAtFocus = (idx, val) => {
        const newOtp = [...otp]

        if (newOtp[idx] !== val) {
            newOtp[idx] = val
            setOtp(newOtp)
            onChange(newOtp.join(''))
        }
    }

    const handleOnKeyDown = (evt, idx) => {
        if (evt.keyCode === KEYCODES.BACKSPACE || evt.key === 'Backspace') {
            evt.preventDefault();
            if (otp[idx] === '') {
                focusInput(idx-1)
            }
            changeCodeAtFocus(idx, '');
        } else if (evt.keyCode === KEYCODES.DELETE || evt.key === 'Delete') {
            evt.preventDefault();
            if (otp[idx] === '') {
                focusInput(idx-1)
            }
            changeCodeAtFocus(idx, '');
        } else if (evt.keyCode === KEYCODES.LEFT_ARROW || evt.key === 'ArrowLeft') {
            evt.preventDefault();
            focusInput(idx-1)
        } else if (evt.keyCode === KEYCODES.RIGHT_ARROW || evt.key === 'ArrowRight') {
            evt.preventDefault();
            focusInput(idx+1)
        }
      };

    return inputs.map((_, idx) => (
        <OTPInput
            id={`otp-${idx}`}
            key={idx}
            maxLength={1}
            min={0}
            max={9}
            type='number'
            autoFocus={idx === 0}
            value={otp[idx]}
            onChange={(evt) => handleChange(evt, idx)}
            onKeyDown={(evt) => handleOnKeyDown(evt, idx)}
        />
    ))
}
