import palette from "./palette"
import components from "./components"
import typography from "./typography"

const defaultTheme = {
    palette,
    typography,
    components,
    customShadows: [
        "0px 6px 6px rgba(0, 0, 0, 0.02)",
        "4px 1px 14px rgba(0, 0, 0, 0.05)"
    ],
    customBorders: [
        "1px solid #DCDFE7"
    ]
}

export default defaultTheme