const typography = {
    fontFamily: '"montserrat-regular", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: 400,
    fontSize: 14,
    fontHeight: '20px',
    h1: {
        fontFamily: 'montserrat-bold',
        fontSize: '2rem',
        lineHeight: '2.625rem',
    },
    h2: {
        fontFamily: 'montserrat-regular',
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
    h3: {
        fontFamily: 'montserrat-bold',
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.01rem',
    },
    h4: {
        fontFamily: 'montserrat-bold',
        fontSize: '1.125rem',
        lineHeight: '1.375rem',
        letterSpacing: '0.01rem',
    },
    h5: undefined,
    h6: {
        fontFamily: 'montserrat-semibold',
        fontSize: '1rem',
        lineHeight: '1.375rem',
        letterSpacing: '0.16px',
    },
    // p16_regular
    content1: {
        fontFamily: 'montserrat-regular',
        fontSize: '1rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.005rem',
    },
    // p16_medium
    content2: {
        fontFamily: 'montserrat-medium',
        fontSize: '1rem',
        lineHeight: '1.25rem',
        letterSpacing: '-0.01rem',
    },
    // p_14_regular
    content3: {
        fontFamily: 'montserrat-regular',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.02rem',
    },
    // p_14_medium
    content4: {
        fontFamily: 'montserrat-medium',
        fontSize: '0.875rem',
        lineHeight: '1.0625rem',
        letterSpacing: '0.02rem',
    },
    // p_14_semibold
    content5: {
        fontFamily: 'montserrat-semibold',
        fontSize: '0.875rem',
        lineHeight: '1.0625rem',
        letterSpacing: '0.02rem',
    },
    // p_14_bold
    content6: {
        fontFamily: 'montserrat-bold',
        fontSize: '0.875rem',
        lineHeight: '1.0625rem',
        letterSpacing: '0.02rem',
    },
    // label_13
    caption: {
        fontFamily: 'montserrat-medium',
        fontSize: '0.8125rem',
        lineHeight: '1rem',
        letterSpacing: '0.02rem',
    },
    // tiny_12
    subtitle1: {
        fontFamily: 'montserrat-medium',
        fontSize: '0.75rem',
        lineHeight: '0.9375rem',
        letterSpacing: '0.04rem',
    },
    // tiny_12_bold
    subtitle2: {
        fontFamily: 'montserrat-semibold',
        fontSize: '0.75rem',
        lineHeight: '0.9375rem',
        letterSpacing: '0.02rem',
    }
}

export default typography;