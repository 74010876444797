import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(({ typography }) => ({
    autoCompleteInput: {
        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0px !important',
            fontSize: 16,
            ...typography.content3
        },
        '& .MuiFormControl-root .MuiOutlinedInput-root': {
            padding: 12,
        }
    }
}))