import { Link } from 'react-router-dom'
import cn from 'classnames';

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

import AddIcon from '@mui/icons-material/AddCircle';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import CheckedIcon from '@mui/icons-material/CheckCircle';
import UncheckedIcon from '@mui/icons-material/CircleOutlined';

import useStyles from "./Payments.styles"
import MasterCardIcon from "../../resources/images/profile/master_card.svg"
import VisaCardIcon from "../../resources/images/profile/visa_card.png"

const CARD_TYPES = {
    MASTER_CARD: 'mastercard',
    VISA_CARD: 'visa'
}

const CARDS = [
    { id: 1, type: 'mastercard', selected: true },
    { id: 2, type: 'visa', selected: false },
]

function CardIcon({ type }) {
    const classes = useStyles()
    switch (type) {
        case CARD_TYPES.MASTER_CARD: return <img src={MasterCardIcon} alt="mastercard" className={classes.masterCardIcon}/>;
        case CARD_TYPES.VISA_CARD: return <img src={VisaCardIcon} alt="visacard" className={classes.visaCardIcon}/>;
        default: return null
    }
}

export default function PaymentsComponent() {
    const classes = useStyles()
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                    <Link to="/profile" className="Link">
                        <IconButton sx={{ color: "shades.black1", marginRight: 2 }}>
                            <BackIcon />
                        </IconButton>
                    </Link>
                    <Typography variant="h3">payment option</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} className={cn(classes.paymentsContainer, 'HideScrollbar')}>
                <Grid container>
                    <Grid item xs={12} className="CardSection">
                        <Typography variant='h4'>stored cards</Typography>
                        <Typography variant='subtitle1' color='text.secondary'>
                            you have stored your card to schedule service with neerbi even smoother.
                        </Typography>
                        <Grid container>
                            {CARDS.map(({ id, type, selected }) => (
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={id} className={classes.paymentCard}>
                                    <Box className="CardDetails">
                                        <CardIcon type={type}/>
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="content2">{type} * * * * 4 5 6 7</Typography>
                                            {selected && <Chip label="primary" size='small' className="PrimaryCard"/>}
                                        </Box>
                                    </Box>
                                    <Checkbox
                                        checked={selected}
                                        onChange={() => { }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        checkedIcon={<CheckedIcon />}
                                        icon={<UncheckedIcon />}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Box>
                            <IconButton color="primary" aria-label="add card" component="span" size='large'>
                                <AddIcon fontSize="inherit" />
                            </IconButton>
                            <Typography variant="content5">add another card</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12} className="CardSection">
                        <Typography variant='h4'>connected paypal account</Typography>
                        <Typography variant="subtitle1" color="text.secondary">add another card</Typography>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12} className="CardSection">
                        <Typography variant='h4'>other payment method</Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            you don't have a connected paypal account
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}