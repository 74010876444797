import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Dialog from 'neerbi-fe-modules/components/dialog'
import LoginNumber from './components/LoginNumber';
import Signup from './components/Signup'
import LoginTitle from './components/LoginTitle';
import LoginOTP from './components/LoginOTP';

import { generateOTP, verifyOTP, updateUserDetails } from './Login.actions'

export default function Login({ open = false, onClose }) {
    const dispatch = useDispatch()
    const [step, setStep] = useState(1)
    const [otp, setOtp] = useState('')
    const [phone, setPhone] = useState('')
    const [loader, setLoader] = useState(false)
    const [nonce, setNonce] = useState('')
    const [isLogin, setLogin] = useState(true)
    const [isSignUp, setSignUp] = useState(false)
    const [successDialog, setSuccessDialog] = useState(false)
    const [user, setUser] = useState({ firstName: '', email: '' })
    const currentUser = useSelector(state => state.app.userDetails)

    useEffect(() => {
        // reset to default values
        if (open) {
            setStep(1)
            setOtp('')
            setPhone('')
            setUser({ firstName: '', email: '' })
            setLoader(false)
            setLogin(true)
            setSignUp(false)
            setSuccessDialog(false)
        }
    }, [open])

    const onAction = () => {
        const generateCallback = (success, error) => {
            if (success) {
                setStep(2)
                setNonce(success.nonce)
            } else {

            }
            setLoader(false)
        }
        const verifyCallback = (success, error) => {
            if (success) {
                if (success?.customer?.newUser) {
                    setLogin(false)
                    setSignUp(true)
                } else {
                    onClose()
                }
            } else {
                // Show error
            }
            setLoader(false)
        }

        if (step === 1) {
            setLoader(true)
            dispatch(generateOTP({ phone }, generateCallback))
        } else {
            setLoader(true)
            dispatch(verifyOTP({ phone, nonce, otp }, verifyCallback))
        }
    }

    const onUserDetailsSave = () => {
        const userCallback = (success, error) => {
            if (success) {
                setSignUp(false)
                setSuccessDialog(true)
            } else {
                // Show error
            }
            setLoader(false)
        }
        setLoader(true)
        dispatch(updateUserDetails({ id: currentUser.id, profileURL: currentUser.profileURL || '', ...user }, userCallback))
    }

    const onBack = useCallback(() => {
        setStep(1)
    }, [])

    const onResendOTP = () => {
        const generateCallback = (success, error) => {
            if (success) {
                setNonce(success.nonce)
            } else {

            }
            setLoader(false)
        }
        dispatch(generateOTP({ phone }, generateCallback))
    }

    const isStep1 = step === 1
    const title = isStep1 ? 'login / signup' : 'enter OTP'
    const actionLabel = isStep1 ? 'continue' : 'verify'

    const renderDialog = () => {
        if (isLogin) {
            return (
                <Dialog
                    open={open}
                    onClose={onClose}
                    title={title}
                    Title={isStep1 ? null : <LoginTitle title={title} onBack={onBack} onClose={onClose} />}
                    actionLabel={actionLabel}
                    actionDisabled={isStep1 ? false : otp.length !== 6}
                    onAction={onAction}
                    loading={loader}
                >
                    {
                        isStep1 ? 
                            <LoginNumber value={phone} onChange={setPhone} /> :
                            <LoginOTP phone={phone} setOtp={setOtp} onResend={onResendOTP} />
                    }
                </Dialog>
            )
        } else if (isSignUp) {
            return (
                <Dialog
                    open={open}
                    onClose={onClose}
                    hideClose={true}
                    title="create profile"
                    actionLabel="let's go"
                    onAction={onUserDetailsSave}
                    loading={loader}
                >
                    <Signup user={user} setUser={setUser} />
                </Dialog>
            )
        } else if (successDialog) {
            return (
                <Dialog
                    open={open}
                    onClose={onClose}
                    actionLabel=""
                >
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h2">profile created successfully</Typography>
                    </Box>
                </Dialog>
            )
        }

        return null
    }

    return renderDialog();
}
