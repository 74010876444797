import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import useStyles from '../Login.styles'
import Otp from 'neerbi-fe-modules/components/otp'
import { useTimer } from 'neerbi-fe-modules/utils/hooks'

export default function LoginOTP({ phone, setOtp, onResend }) {
    const classes = useStyles()
    const [minutesLeft, secondsLeft, resetTimer] = useTimer(30)

    const handleResend = () => {
        resetTimer()
        onResend()
    }

    return (
        <>
            <Typography variant="h2">Enter the phone verification code</Typography>
            <Typography variant="content5" color="text.secondary">
                We sent a code to {phone}. Didn't receive the code?
            </Typography><br />
            <Button
                variant='text'
                className={classes.resendButton}
                onClick={handleResend}
                disabled={Boolean(+minutesLeft || +secondsLeft)}
            >
                send again
            </Button>
            <Box className={classes.otpContainer}>
                <Box display="flex" alignItems="center" >
                    <Otp
                        inputLength={6}
                        onChange={setOtp}
                    />
                </Box>
                <Box marginTop={2}>
                    <Typography variant='content4'>{minutesLeft}:{secondsLeft}</Typography>
                </Box>
            </Box>
        </>
    )
}