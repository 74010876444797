import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Avatar from "@mui/material/Avatar"

import PhotoCamera from '@mui/icons-material/PhotoCamera'

import InputField from 'neerbi-fe-modules/components/input-field'
import useStyles from '../Login.styles'
import { uploadProfilePicture } from '../../../profile/Profile.actions'


const Input = styled('input')({
    display: 'none',
});

export default function Signup({ user, setUser }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const profileURL = useSelector(state => state.app.userDetails.profileURL || '')

    const handleNameChange = (evt) => setUser(user => ({ ...user, firstName: evt.target.value }))

    const handleEmailChange = (evt) => setUser(user => ({ ...user, email: evt.target.value }))

    const handleFileUpload = (evt) => {
        const callback = () => {
            setLoader(false)
        }
        const photo = evt.target.files[0];
        const formData = new FormData();

        formData.append("file", photo);
        setLoader(true)
        dispatch(uploadProfilePicture(formData, callback))
    }

    return (
        <>
            <Typography variant="h2">Tell us a little about you!</Typography>
            <Box position="relative" width={140} height={140} marginTop={4}>
                <Avatar sx={{ width: 140, height: 140 }} src={profileURL} />
                <Box className={classes.profileIcon}>
                    <label htmlFor="upload-profile-image">
                        <Input accept="image/*" id="upload-profile-image" type="file" onChange={handleFileUpload} />
                        <IconButton color="primary" aria-label="upload picture" component="span" sx={{ padding: 0 }}>
                            <PhotoCamera sx={{ color: "common.white", fontSize: '1.3rem' }} />
                        </IconButton>
                    </label>
                </Box>
            </Box>
            <Box className={classes.userDetailsContainer}>
                <InputField
                    id="name-input"
                    label="enter name"
                    value={user.firstName}
                    onChange={handleNameChange}
                    variant="filled"
                    className={classes.inputField}
                /><br />
                <InputField
                    id="email-input"
                    label="enter email"
                    value={user.email}
                    onChange={handleEmailChange}
                    variant="filled"
                    className={classes.inputField}
                />
            </Box>
        </>
    )
}