import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/CloseOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBackOutlined';

import useStyles from '../Login.styles'

export default function LoginTitle({ title, onBack, onClose }) {
    const classes = useStyles()

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <IconButton size='small' className={classes.closeButton} onClick={onBack}>
                <ArrowBackIcon fontSize='inherit' />
            </IconButton>
            <Typography variant="h4" textAlign="center">{title}</Typography>
            <IconButton size='small' className={classes.closeButton} onClick={onClose}>
                <CloseIcon fontSize='inherit' />
            </IconButton>
        </Box>
    )
}