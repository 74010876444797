import useStyles from '../Home.styles'
import StarIcon from '../../resources/images/home/star.svg'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime'

export default function VendorCard({ name, services, rating, distance }) {
    const classes = useStyles()
    const serviceLabel = services.length <= 3 ? services.join(' | ') : `${services.slice(0, 3).join(' | ')} and more`

    return (
        <div className={classes.vendorCard}>
            <Typography variant='h3'>{name}</Typography>
            <Typography variant='content1' className='ServiceLabel'>{serviceLabel}</Typography>
            <Box display='flex' justifyContent='space-between' className='ServiceContent'>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Typography variant='subtitle1' className='ServiceSubtitle'>rating</Typography>
                    <Box display='flex' alignItems='center'>
                        <img src={StarIcon} alt="*" />&nbsp;
                        <Typography variant='content2'>{rating}</Typography>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Typography variant='subtitle1' className='ServiceSubtitle'>distance from you</Typography>
                    <Box display='flex' alignItems='center'>
                        <AccessTimeIcon sx={{ color: 'primary.main' }}/>&nbsp;
                        <Typography variant='content2'>{distance}</Typography>
                    </Box>
                    
                </Box>
            </Box>
        </div>
    )
}