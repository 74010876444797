export function replaceQueryParams(url = '', params = {}) {
    const keys = Object.keys(params)
    let newUrl = url

    for (let idx = 0, len = keys.length; idx < len; idx++) {
        const curKey = keys[idx]

        newUrl = newUrl.replace(`{${curKey}}`, params[curKey])
    }

    return newUrl
}