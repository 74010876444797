import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(({ palette }) => ({
    container: {
        height: '100%',
    },
    ordersContainer: {
        height: 'calc(100% - 45px)',
        overflow: 'auto',
    },
    orderCard: {
        margin: '1.5rem 0',
        border: '1px solid #DCDFE7',
        borderRadius: 12,
        '& .OrderBanner': {
            backgroundColor: palette.secondary.main,
            borderRadius: '11px 11px 0 0',
            paddingLeft: '1.25rem',
        },
        '& .OrderContent': {
            padding: '1rem 1.25rem',
        }
    }
}))