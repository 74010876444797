import { SET_USER_DETAILS, SET_AUTHENTICATION, TRIGGER_NOTIFICATION } from './App.constants'

const initial = {
    JWTToken: '',
    notification: { open: false, message: '' },
    userDetails: {},
    isAuthenticated: false,
}

export default function reducer(state=initial, { type, payload }) {
    switch(type) {
        case SET_AUTHENTICATION: return { ...state, JWTToken: payload.jwt, isAuthenticated: payload.isAuthenticated }
        case TRIGGER_NOTIFICATION: return { ...state, notification: { ...state.notification, ...payload} }
        case SET_USER_DETAILS: return { ...state, userDetails: { ...state.userDetails, ...payload } }
        default: return { ...state }
    }
}