import Typography from '@mui/material/Typography'
import MuiDialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import CloseIcon from '@mui/icons-material/CloseOutlined'

import useStyles from './Dialog.styles'

export default function Dialog({
    children,
    open = false,
    title = "",
    Title,
    actionLabel = "submit",
    actionDisabled = false,
    onAction,
    loading = false,
    onClose = () => { },
    hideClose = false
}) {
    const classes = useStyles()
    return (
        <MuiDialog open={open} className={classes.dialogRoot}>
            <DialogTitle>
                {Title ? Title :
                    <Box>
                        <Typography variant="h4" textAlign="center">{title}</Typography>
                        {
                            !hideClose &&
                                <IconButton size='small' className={classes.closeButton} onClick={onClose}>
                                    <CloseIcon fontSize='inherit' />
                                </IconButton>
                        }
                    </Box>
                }
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {actionLabel && (
                <DialogActions>
                    <Button variant='contained' className={classes.saveButton} onClick={onAction} disabled={actionDisabled}>
                        {loading ? <CircularProgress
                            size={24}
                            sx={{ color: 'common.white' }}
                        /> : actionLabel}
                    </Button>
                </DialogActions>
            )}
        </MuiDialog>
    )
}
