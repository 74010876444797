import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'

// Reducers
import appReducer from '../App.reducer'
import { Cookies } from 'react-cookie'

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({ app: appReducer })

const resetApplication = () => {
  const cookies = new Cookies()
  cookies.remove('JWTToken')
  cookies.remove('user')
}

const rootReducerWrapper = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined
    resetApplication()
  }
  return rootReducer(state, action)
}

export const store = createStore(
    rootReducerWrapper,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
)

sagaMiddleware.run(rootSaga)
