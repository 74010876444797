import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(() => ({
    dialogRoot: {
        '& .MuiDialog-paper': {
            padding: 24,
            minWidth: 568,
        },
        '& .MuiDialogTitle-root': {
            padding: '0 0 40px 0',
        },
        '& .MuiDialogContent-root': {
            padding: '24px 0',
        },
        '& .MuiDialogActions-root': {
            padding: '20px 0 0 0',
            '& .MuiButton-root': {
                width: '100%'
            }
        },
    },
    closeButton: {
        '&.MuiIconButton-root': {
            position: 'absolute',
            right: 24,
            top: 20,
        }
    }
}))